/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/17 15:37
 * @update 2019/01/17 15:37
 * @file src/actions/user-actions.js
 */

import Api from '../services/api.js';
import * as types from '../../src/actions/types'


export function deleteTask(param, cb) {
    return () => {
        Api.put('/w18f1014/del', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                }
            });
    };
}

export function loadCaseTabW17F1026(param, cb) {
    return (_dispatch) => {
        Api.put('/w18f1060/get-list-new', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    _dispatch(loadCaseTabW17F1026Complete(result.data));
                }
            });
    };
}

function loadCaseTabW17F1026Complete(data) {
    return {
        type: types.W17F1026_LOAD_CASETAB_COMPLETE,
        dataGridCase: data
    }
}

export function loadGridDelivery(param, cb) {
    return (_dispatch) => {
        Api.put('/w18f1012/load-grid', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
                _dispatch(loadGridDeliveryComplete(result.data));
            }
        });
    };
}

function loadGridDeliveryComplete(data) {
    return {
        type: types.W17F1026_LOAD_GRID_DELIVERY_COMPLETE,
        dataGridDelivery: data
    }
}

export function deleteDelivery(param, cb) {
    return (_dispatch) => {
        Api.put('/W18F1012/delete-delivery', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
            }
        });
    };
}
//cbo

export function getCboCompanyType(param, cb) {
    return (_dispatch) => {
        Api.get('/w17f1026/load-combo-company-type', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
                _dispatch({
                    type: types.W17F1026_LOAD_CBO_COMPANY_TYPE_COMPLETE,
                    cboCompanyType: result.data
                });
            }
        });
    };
}

export function getCboCaseSource(param, cb) {
    return (_dispatch) => {
        Api.get('/w17f1026/load-combo-case-source', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
                _dispatch({
                    type: types.W17F1026_LOAD_CBO_CASE_SOURCE_COMPLETE,
                    cboCaseSource: result.data
                });
            }
        });
    };
}
export function getCboIndustryGroup(param, cb) {
    return (_dispatch) => {
        Api.get('/w17f1026/load-combo-industry-group', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
                _dispatch({
                    type: types.W17F1026_LOAD_CBO_INDUSTRY_GROUP_COMPLETE,
                    cboIndustryGroup: result.data
                });
            }
        });
    };
}
export function getCboCompanyGroup(param, cb) {
    return (_dispatch) => {
        Api.get('/w17f1026/load-combo-company-group', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
                _dispatch({
                    type: types.W17F1026_LOAD_CBO_COMPANY_GROUP_COMPLETE,
                    cboCompanyGroup: result.data
                });
            }
        });
    };
}
export function getCboCustomerLevel(param, cb) {
    return (_dispatch) => {
        Api.get('/w17f1026/load-combo-customer-level', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
                _dispatch({
                    type: types.W17F1026_LOAD_CBO_CUSTOM_LEVEL_COMPLETE,
                    cboCustomerLevel: result.data
                });
            }
        });
    };
}
export function getCboGroupSales(param, cb) {
    return (_dispatch) => {
        Api.get('/w17f1026/load-combo-group-sales', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
                _dispatch({
                    type: types.W17F1026_LOAD_CBO_GROUP_SALES_COMPLETE,
                    cboGroupSales: result.data
                });
            }
        });
    };
}
export function getCboSalesPerson(param, cb) {
    return (_dispatch) => {
        Api.put('/w17f1026/load-combo-sales-person', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
                _dispatch({
                    type: types.W17F1026_LOAD_CBO_SALES_PERSON_COMPLETE,
                    cboSalesPerson: result.data
                });
            }
        });
    };
}
export function getCboCountry(param, cb) {
    return (_dispatch) => {
        Api.get('/w17f1026/load-combo-country', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
                _dispatch({
                    type: types.W17F1026_LOAD_CBO_COUNTRY_COMPLETE,
                    cboCountry: result.data
                });
            }
        });
    };
}
export function getCboProvince(param, cb) {
    return (_dispatch) => {
        Api.get('/w17f1026/load-combo-province', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
                _dispatch({
                    type: types.W17F1026_LOAD_CBO_PROVINCE_COMPLETE,
                    cboProvince: result.data
                });

            }
        });
    };
}
export function getCboDistrict(param, cb) {
    return (_dispatch) => {
        Api.get('/w17f1026/load-combo-district', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
                _dispatch({
                    type: types.W17F1026_LOAD_CBO_DISTRICT_COMPLETE,
                    cboDistrict: result.data
                });
            }
        });
    };
}
export function getCboWard(param, cb) {
    return (_dispatch) => {
        Api.get('/w17f1026/load-combo-ward', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
                _dispatch({
                    type: types.W17F1026_LOAD_CBO_WARD_COMPLETE,
                    cboWard: result.data
                });

            }
        });
    };
}
export function getNewKey(param, cb) {
    return (_dispatch) => {
        Api.get('/w17f1026/get-new-key', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
            }
        });
    };
}
export function checkCompanyAuto(param, cb) {
    return (_dispatch) => {
        Api.get('/w17f1026/check-company-auto', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
            }
        });
    };
}

export function getFormMaster(param, cb) {
    return (_dispatch) => {
        Api.put('/w17f1026/load-form', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
                _dispatch({
                    type: types.W17F1026_LOAD_FORM_MASTER_COMPLETE,
                    loadFormMaster: result.data
                });
            }
        });
    };
}
export function addNew(param, cb) {
    return (_dispatch) => {
        Api.post('/w17f1026/add', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
            }
        });
    };
}
export function edit(param, cb) {
    return (_dispatch) => {
        Api.put('/w17f1026/edit', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
            }
        });
    };
}
export function confirmSave(param, cb) {
    return (_dispatch) => {
        Api.put('/w17f1026/confirm', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
            }
        });
    };
}
// Check Edit
export function checkEditPrice(param, cb) {
    return (dispatch) => {
        Api.put('/w17f2000/check-edit', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}
export function deleteOrder(param, cb) {
    return () => {
        Api.delete('/w19f2000/delete', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}
export function checkEditOrder(param, cb) {
    return () => {
        Api.put('/w19f2000/check-edit', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

// Check Status
export function checkAddOrder(param, cb) {
    return (dispatch) => {
        Api.put('/w17f1026/check-createso', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}
export function deleteQuotation(param, cb) {
    return (dispatch) => {
        Api.delete('/w17f1026/quotation-delete', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    }
}
//request
export function getRequestGrid(param, cb) {
    return (_dispatch) => {
        Api.put('/w17f1026/load-request-grid', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
                _dispatch({
                    type: types.W17F1026_GET_REQUEST_GRID_COMPLETE,
                    getRequestGrid: result.data
                });
            }
        });
    };
}
export function checkAddQuotation(param, cb) {
    return (_dispatch) => {
        Api.put('/w17f1026/check-add-quotation', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
            }
        });
    };
}
export function deleteRequest(param, cb) {
    return (_dispatch) => {
        Api.delete('/w17f1026/delete', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
            }
        });
    };
}
export function confirmDeleteRequest(param, cb) {
    return (_dispatch) => {
        Api.put('/w17f1026/delete-confirm', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
            }
        });
    };
}
export function checkEditRequest(param, cb) {

    return (dispatch) => {
        Api.put('/w17f2025/check-edit', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);

                }
            });
    };
}

export function btnConvertAction(param, cb) {

    return (dispatch) => {
        Api.put('/w17f1026/update-company-id', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);

                }
            });
    };
}

export function loadProgressBarStatus(param, cb) {
    return () => {
        Api.put('/w17f1026/load-progress-bar-status', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);

                }
            });
    };
}

export function getCustomerSettingValue(cb) {
    return (_dispatch) => {
        Api.get('/w17f1026/get-value')
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    _dispatch(getCustomerSettingValueComplete(result.data));
                }
            });
    };
}

function getCustomerSettingValueComplete(data) {
    return {
        type: types.W17F1026_GET_CUSTOMER_SETTING_VALUE_COMPLETE,
        customerSettingValue: data
    }
}