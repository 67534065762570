
/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/01/12 10:37
 * @update 2019/01/12 10:37
 * @file src/actions/types.js
 */

export const GET_SETTING_COMPLETE = 'GET_SETTING_COMPLETE';

export const CHECKING_STORE_COMPLETE = 'CHECKING_STORE_COMPLETE';

export const SETTING_LIST_COMPLETE = 'SETTING_LIST_COMPLETE';
export const SETTING_ADD_COMPLETE = 'SETTING_ADD_COMPLETE';
export const SETTING_EDIT_COMPLETE = 'SETTING_EDIT_COMPLETE';
export const SETTING_DELETE_COMPLETE = 'SETTING_DELETE_COMPLETE';
export const SETTING_DELETE_ALL_COMPLETE = 'SETTING_DELETE_ALL_COMPLETE';

export const TEMPLATE_DROPDOWN_REPORTTEMPLATE_COMPLETE = 'TEMPLATE_DROPDOWN_REPORTTEMPLATE_COMPLETE';
export const TEMPLATE_DROPDOWN_SALEPERSON_COMPLETE = 'TEMPLATE_DROPDOWN_SALEPERSON_COMPLETE';
export const TEMPLATE_DROPDOWN_TYPETIME_COMPLETE = 'TEMPLATE_DROPDOWN_TYPETIME_COMPLETE';
export const TEMPLATE_DROPDOWN_TASKTYPE_COMPLETE = 'TEMPLATE_DROPDOWN_TASKTYPE_COMPLETE';
export const TEMPLATE_DROPDOWN_REPORTTYPE_COMPLETE = 'TEMPLATE_DROPDOWN_REPORTTYPE_COMPLETE';
export const TEMPLATE_DROPDOWN_AREATEMPLATE_COMPLETE = 'TEMPLATE_DROPDOWN_AREATEMPLATE_COMPLETE';
export const TEMPLATE_DROPDOWN_CUSTOMERLEVEL_COMPLETE = 'TEMPLATE_DROPDOWN_CUSTOMERLEVEL_COMPLETE';
export const TEMPLATE_DROPDOWN_COMPANYSTATUS_COMPLETE = 'TEMPLATE_DROPDOWN_COMPANYSTATUS_COMPLETE';

export const USER_LIST_COMPLETE = 'USER_LIST_COMPLETE';
export const USER_GET_COMPLETE = 'USER_GET_COMPLETE';
export const USER_ADD_COMPLETE = 'USER_ADD_COMPLETE';
export const USER_EDIT_COMPLETE = 'USER_EDIT_COMPLETE';
export const USER_DELETE_COMPLETE = 'USER_DELETE_COMPLETE';
export const USER_DELETE_ALL_COMPLETE = 'USER_DELETE_ALL_COMPLETE';
export const USER_GET_PERMISSION_COMPLETE = 'USER_GET_PERMISSION_COMPLETE';

export const W17_LIST_NOTIFY_COMPLETE = 'W17_LIST_NOTIFY_COMPLETE';
export const W17_LIST_MENU_COMPLETE = 'W17_LIST_MENU_COMPLETE';

export const W17_W17F1555_GRID_COMPLETE = 'W17_W17F1555_GRID_COMPLETE';
export const W17_W17F1555_COMBO_COMPLETE = 'W17_W17F1555_COMBO_COMPLETE';
export const W17_W17F1555_CHECKEDIT_COMPLETE = 'W17_W17F1555_CHECKEDIT_COMPLETE';
export const W17_W17F1555_CHECKDELETE_COMPLETE = 'W17_W17F1555_CHECKDELETE_COMPLETE';
export const W17_W17F1556_CHECKADD_COMPLETE = 'W17_W17F1556_CHECKADD_COMPLETE';
export const W17_W17F1556_DELETE_COMPLETE = 'W17_W17F1556_DELETE_COMPLETE';
export const W17_W17F1556_ACTION_COMPLETE = 'W17_W17F1556_ACTION_COMPLETE';

export const W17_W17F0001_DELETE_COMPLETE = 'W17_W17F0001_DELETE_COMPLETE';
export const W17_W17F0001_EDIT_COMPLETE = 'W17_W17F0001_EDIT_COMPLETE';
export const W17_W17F0001_ADD_COMPLETE = 'W17_W17F0001_ADD_COMPLETE';
export const W17_W17F0001_GET_COMPLETE = 'W17_W17F0001_GET_COMPLETE';
export const W17_W17F0001_GRID_COMPLETE = 'W17_W17F0001_GRID_COMPLETE';

export const W17_W17F1002_SENDMAIL_COMPLETE     = 'W17_W17F1002_SENDMAIL_COMPLETE';
export const W17_W17F1002_SAVEMAIL_COMPLETE     = 'W17_W17F1002_SAVEMAIL_COMPLETE';

export const W17F0040_LOAD_GRID_REPORTTYPE_COMPLETE     = 'W17F0040_LOAD_GRID_REPORTTYPE_COMPLETE';
export const W17F0040_LOAD_GRID_REPORT_COMPLETE     = 'W17F0040_LOAD_GRID_REPORT_COMPLETE';
export const W17F0041_LOAD_CB_REPORTTYPE_COMPLETE     = 'W17F0041_LOAD_CB_REPORTTYPE_COMPLETE';
export const W17_W17F0041_ADD_COMPLETE     = 'W17_W17F0041_ADD_COMPLETE';
export const W17_W17F0041_EDIT_COMPLETE     = 'W17_W17F0041_EDIT_COMPLETE';
export const W17_W17F0041_DELETE_COMPLETE     = 'W17_W17F0041_DELETE_COMPLETE';


/*
* W17F0003
* Danh sách lịch sử tác động
* */
export const W17_W17F0003_LOAD_COMPLETE = 'W17_W17F0003_LOAD_COMPLETE';
export const W17_W17F0003_LOADCOMBO_COMPLETE = 'W17_W17F0003_LOADCOMBO_COMPLETE';

/*
* W17F0020
*
* */
export const W17_W17F0020_LOAD_COMPLETE = 'W17_W17F0020_LOAD_COMPLETE';
/*
* W17F1025
* */
export const W17F1025_STORE_COMPLETE = 'W17F1025_STORE_COMPLETE'
export const W17F1025_COMPERTITOR_COMPLETE = 'W17F1025_COMPERTITOR_COMPLETE';
export const W17_W17F1025_FILTER_COMPLETE = 'W17_W17F1025_FILTER_COMPLETE';
export const W17_W17F1025_DEL_COMPLETE = 'W17_W17F1025_DEL_COMPLETE';
export const W17_W17F1025_COMPLETE = 'W17_W17F1025_COMPLETE';
//
/*
* W17F1026
* */

export const W17F1026_COMPLETE = 'W17F1026_COMPLETE';
export const W17F1026_GRID_COMPLETE = 'W17F1026_GRID_COMPLETE';
export const W17F1026_LOAD_GRID_DELIVERY_COMPLETE = 'W17F1026_LOAD_GRID_DELIVERY_COMPLETE';
export const W17F1026_ACTION_TAB_COMPLETE = 'W17F1026_ACTION_TAB_COMPLETE';
export const W17F1026_LOAD_CASETAB_COMPLETE = 'W17F1026_LOAD_CASETAB_COMPLETE';

export const W17F1026_LOAD_CBO_COMPANY_TYPE_COMPLETE = "W17F1026_LOAD_CBO_COMPANY_TYPE_COMPLETE"
export const W17F1026_LOAD_CBO_CASE_SOURCE_COMPLETE = "W17F1026_LOAD_CBO_CASE_SOURCE_COMPLETE"
export const W17F1026_LOAD_CBO_INDUSTRY_GROUP_COMPLETE = "W17F1026_LOAD_CBO_INDUSTRY_GROUP_COMPLETE"
export const W17F1026_LOAD_CBO_COMPANY_GROUP_COMPLETE = "W17F1026_LOAD_CBO_COMPANY_GROUP_COMPLETE"
export const W17F1026_LOAD_CBO_CUSTOM_LEVEL_COMPLETE = "W17F1026_LOAD_CBO_CUSTOM_LEVEL_COMPLETE"
export const W17F1026_LOAD_CBO_GROUP_SALES_COMPLETE = "W17F1026_LOAD_CBO_GROUP_SALES_COMPLETE"
export const W17F1026_LOAD_CBO_SALES_PERSON_COMPLETE = "W17F1026_LOAD_CBO_SALES_PERSON_COMPLETE"
export const W17F1026_LOAD_CBO_COUNTRY_COMPLETE = "W17F1026_LOAD_CBO_COUNTRY_COMPLETE"
export const W17F1026_LOAD_CBO_PROVINCE_COMPLETE = "W17F1026_LOAD_CBO_PROVINCE_COMPLETE"
export const W17F1026_LOAD_CBO_DISTRICT_COMPLETE = "W17F1026_LOAD_CBO_DISTRICT_COMPLETE"
export const W17F1026_LOAD_CBO_WARD_COMPLETE = "W17F1026_LOAD_CBO_WARD_COMPLETE"
export const W17F1026_LOAD_FORM_MASTER_COMPLETE = "W17F1026_LOAD_FORM_MASTER_COMPLETE"
export const W17F1026_GET_CUSTOMER_SETTING_VALUE_COMPLETE = "W17F1026_GET_CUSTOMER_SETTING_VALUE_COMPLETE"

//
export const W17F1026_GET_REQUEST_GRID_COMPLETE = "W17F1026_GET_REQUEST_GRID_COMPLETE"
//

// W17F2025
export const W17F2025_GET_CBO_STATUS_COMPLETE = 'W17F2025_GET_CBO_STATUS_COMPLETE';
export const W17F2025_GET_CBO_SALES_PERSON_COMPLETE= 'W17F2025_GET_CBO_SALES_PERSON_COMPLETE';
export const W17F2025_GET_CBO_CASE_SOURCE_COMPLETE= 'W17F2025_GET_CBO_CASE_SOURCE_COMPLETE';
export const W17F2025_GET_CBO_COLLABORATOR_COMPLETE = 'W17F2025_GET_CBO_COLLABORATOR_COMPLETE';
export const W17F2025_GET_FORM_COMPLETE = 'W17F2025_GET_FORM_COMPLETE';



//W17F2025

export const W17F2050_LOAD_GRID_COMPLETE = 'W17F2050_LOAD_GRID_COMPLETE';
//W17F2051
export const W17F2051_GET_CBO_CATEGORY_TYPE_COMPLETE= "W17F2051_GET_CBO_CATEGORY_TYPE_COMPLETE"
export const W17F2051_GET_CBO_GENDER_COMPLETE= "W17F2051_GET_CBO_GENDER_COMPLETE"
export const W17F2051_GET_ANA_MASTER_COMPLETE= "W17F2051_GET_ANA_MASTER_COMPLETE"
export const W17F2051_GET_ANA_MASTER_CAPTION_COMPLETE= "W17F2051_GET_ANA_MASTER_CAPTION_COMPLETE"
export const W17F2051_LOAD_FORM_MASTER_COMPLETE= "W17F2051_LOAD_FORM_MASTER_COMPLETE"

//W17F5560
export const W17F5560_GET_CBO_INVENTORY_TYPE_COMPLETE= "W17F5560_GET_CBO_INVENTORY_TYPE_COMPLETE";
export const W17F5560_GET_CBO_INVENTORY_COMPLETE= "W17F5560_GET_CBO_INVENTORY_COMPLETE";
export const W17F5560_GET_CBO_TYPECODE_COMPLETE= "W17F5560_GET_CBO_TYPECODE_COMPLETE";
export const W17F5560_GET_CBO_CODE_COMPLETE= "W17F5560_GET_CBO_CODE_COMPLETE";
export const W17F5560_GET_FORM_COMPLETE= "W17F5560_GET_FORM_COMPLETE";
export const W17F5560_GET_COLUMNS_COMPLETE= "W17F5560_GET_COLUMNS_COMPLETE";
export const W17F5560_GET_CBO_WAREHOUSE_COMPLETE= "W17F5560_GET_CBO_WAREHOUSE_COMPLETE";
export const W17F5560_GET_QUANTITY_DECIMAL_COMPLETE= "W17F5560_GET_QUANTITY_DECIMAL_COMPLETE";

//W17F5565
export const W17F5565_GET_FORM_COMPLETE= "W17F5565_GET_FORM_COMPLETE";


//
export const W18F1010_STORE_COMPLETE = 'W18F1010_STORE_COMPLETE';
export const W18_W18F1000_COMPLETE = 'W18_W18F1000_COMPLETE';
export const W18_W18F1000_DELETE_COMPLETE = 'W18_W18F1000_DELETE_COMPLETE';
export const W18_W18F1001_ADD_COMPLETE = 'W18_W18F1001_ADD_COMPLETE';
export const W18_W18F1001_EDIT_COMPLETE = 'W18_W18F1001_EDIT_COMPLETE';
export const W18F1010_COMPERTITOR_COMPLETE = 'W18F1010_COMPERTITOR_COMPLETE';

export const W18_W18F1010_FILTER_COMPLETE = 'W18_W18F1010_FILTER_COMPLETE';
export const W18_W18F1010_DEL_COMPLETE = 'W18_W18F1010_DEL_COMPLETE';



export const W18_W18F1002_COMPLETE = 'W18_W18F1002_COMPLETE';
export const W18_W18F1010_COMPLETE = 'W18_W18F1010_COMPLETE';
export const W18_W18F1013_COMPLETE = 'W18_W18F1013_COMPLETE';
export const W18F1013_ADD_COMPLETE = 'W18F1013_ADD_COMPLETE';
export const W18F1013_EDIT_COMPLETE = 'W18F1013_EDIT_COMPLETE';
export const W18F1013_DELETE_COMPLETE = 'W18F1013_DELETE_COMPLETE';
export const W18F1013_CHECKEXISTMOBILE_COMPLETE = 'W18F1013_CHECKEXISTMOBILE_COMPLETE';


export const W18_W18F1030_COMPLETE = 'W18_W18F1030_COMPLETE';
export const W18_W18F1031_DETAIL_COMPLETE = 'W18_W18F1031_DETAIL_COMPLETE';
export const W18_W18F1031_STORE_COMPLETE = 'W18_W18F1031_STORE_COMPLETE';
export const W18_W18F1031_ACTION_COMPLETE = 'W18_W18F1031_ACTION_COMPLETE';
export const W18_W18F1031_ADD_COMPLETE = 'W18_W18F1031_ADD_COMPLETE';
export const W18_W18F1031_EDIT_COMPLETE = 'W18_W18F1031_EDIT_COMPLETE';
export const W18_W18F1031_DELETE_COMPLETE = 'W18_W18F1031_DELETE_COMPLETE';
export const W18_W18F1031_GroupSales_COMPLETE = 'W18_W18F1031_GroupSales_COMPLETE';
export const CHECKING_STOREW18F1030_COMPLETE = 'CHECKING_STOREW18F1030_COMPLETE';
export const W18_W18F1031_LOADATTCH_COMPLETE = 'W18_W18F1031_LOADATTCH_COMPLETE';
export const W18_W18F1031_LOADGROUPSALES_COMPLETE = 'W18_W18F1031_LOADGROUPSALES_COMPLETE';
export const W18_W18F1031_STATUS_COMPLETE = 'W18_W18F1031_STATUS_COMPLETE';
export const W18_W18F1030_QUANTITY_COMPLETE = 'W18_W18F1030_QUANTITY_COMPLETE';


export const W18_W18F1012_COMPLETE = 'W18_W18F1012_COMPLETE';
export const W18_W18F1011_DATA_MASTER_COMPLETE = 'W18_W18F1011_DATA_MASTER_COMPLETE';
export const W18_W18F1012_GRID_COMPLETE = 'W18_W18F1012_GRID_COMPLETE';
export const W18_W18F1012_LOAD_GRID_DELIVERY_COMPLETE = 'W18_W18F1012_LOAD_GRID_DELIVERY_COMPLETE';
export const W18_W18F1011_CBOCOMPANYSTATUS_COMPLETE = 'W18_W18F1011_CBOCOMPANYSTATUS_COMPLETE';
export const W18_W18F1011_CBOCASESOURCE_COMPLETE = 'W18_W18F1011_CBOCASESOURCE_COMPLETE';
export const W18_W18F1011_CBOCOMPANYGROUP_COMPLETE = 'W18_W18F1011_CBOCOMPANYGROUP_COMPLETE';
export const W18_W18F1011_CBOGROUPSALES_COMPLETE = 'W18_W18F1011_CBOGROUPSALES_COMPLETE';
export const W18_W18F1011_CBOSALESPERSON_COMPLETE = 'W18_W18F1011_CBOSALESPERSON_COMPLETE';
export const W18_W18F1011_CBOINDUTRYGROUP_COMPLETE = 'W18_W18F1011_CBOINDUTRYGROUP_COMPLETE';
export const W18_W18F1011_CBOPROVINCE_COMPLETE = 'W18_W18F1011_CBOPROVINCE_COMPLETE';
export const W18_W18F1011_CBODISTRICT_COMPLETE = 'W18_W18F1011_CBODISTRICT_COMPLETE';
export const W18_W18F1011_CBOWARD_COMPLETE = 'W18_W18F1011_CBOWARD_COMPLETE';
export const W18_W18F1011_GRID_COMPLETE = 'W18_W18F1011_GRID_COMPLETE';
export const W18_W18F1011_CBOCUSTOMERLEVEL_COMPLETE = 'W18_W18F1011_CBOCUSTOMERLEVEL_COMPLETE';
export const W18_W18F1011_CBONORM_COMPLETE = 'W18_W18F1011_CBONORM_COMPLETE';
export const W18_W18F1011_CBODATA_COMPLETE = 'W18_W18F1011_CBODATA_COMPLETE';
export const W18_W18F1012_ACTION_TAB_COMPLETE = 'W18_W18F1012_ACTION_TAB_COMPLETE';
export const W18_W18F1011_CBOCOMPANYSIZE_COMPLETE = 'W18_W18F1011_CBOCOMPANYSIZE_COMPLETE';
export const W18_W18F1011_CBOREVENUE_COMPLETE = 'W18_W18F1011_CBOREVENUE_COMPLETE';
export const W18_W18F1011_CBOCOMPETITOR_COMPLETE = 'W18_W18F1011_CBOCOMPETITOR_COMPLETE';
export const W18_W18F1011_CBOPARTNER_COMPLETE = 'W18_W18F1011_CBOPARTNER_COMPLETE';
export const W18_W18F1011_COMPANYNAME_COMPLETE = 'W18_W18F1011_COMPANYNAME_COMPLETE';
export const W18_W18F1012_LOAD_CASETAB_COMPLETE = 'W18_W18F1012_LOAD_CASETAB_COMPLETE';


export const W18F4000_LOAD_REPORT_COMPLETE      = 'W18F4000_LOAD_REPORT_COMPLETE';
export const W18F4000_LOAD_TASK_TYPE_COMPLETE   = 'W18F4000_LOAD_TASK_TYPE_COMPLETE';
export const W18F4000_LOAD_STATUS_COMPLETE      = 'W18F4000_LOAD_STATUS_COMPLETE';

export const W18F4001_LOAD_CUSTOMER_STATUS_COMPLETE      = 'W18F4001_LOAD_CUSTOMER_STATUS_COMPLETE';
export const W18F4001_LOAD_CUSTOMER_LEVEL_COMPLETE       = 'W18F4001_LOAD_CUSTOMER_LEVEL_COMPLETE';
export const W18F4001_LOAD_FIELD_GROUP_COMPLETE          = 'W18F4001_LOAD_FIELD_GROUP_COMPLETE';
export const W18F4001_CBO_GENDER_COMPLETE                = 'W18F4001_CBO_GENDER_COMPLETE';

/*
* W18F1014
* Cap nhat hoat dong
* VINHLE
* */
export const W18_W18F1014_CBOCONTACT_COMPLETE = 'W18_W18F1014_CBOCONTACT_COMPLETE';
export const W18_W18F1014_CBOPRIORITY_COMPLETE = 'W18_W18F1014_CBOPRIORITY_COMPLETE';
export const W18_W18F1014_CBOTASKSTATUS_COMPLETE = 'W18_W18F1014_CBOTASKSTATUS_COMPLETE';
export const W18_W18F1014_CBOTASKTYPE_COMPLETE = 'W18_W18F1014_CBOTASKTYPE_COMPLETE';
export const W18_W18F1014_DATA_MASTER_COMPLETE = 'W18_W18F1014_DATA_MASTER_COMPLETE';
export const W18_W18F1014_DATAATTACH_COMPLETE = 'W18_W18F1014_DATAATTACH_COMPLETE';

/*
* W17F1000
* He thong co cau kinh doanh
* VINHLE
* */
export const W17_W17F1000_DATATREELIST_COMPLETE = 'W17_W17F1000_DATATREELIST_COMPLETE';
export const W17_W17F1000_DATAORGLIST_COMPLETE = 'W17_W17F1000_DATAORGLIST_COMPLETE';

export const W18_W18F1020_CBO_GROUPSALE_COMPLETE = 'W18_W18F1020_CBO_GROUPSALE_COMPLETE';
export const W18_W18F1020_CBO_SALEPERSON_COMPLETE = 'W18_W18F1020_CBO_SALEPERSON_COMPLETE';
export const W18_W18F1020_CBO_TYPETIME_COMPLETE = 'W18_W18F1020_CBO_TYPETIME_COMPLETE';
export const W18_W18F1020_GRID_COMPLETE = 'W18_W18F1020_GRID_COMPLETE';
export const W18_W18F1020_DETAIL_COMPLETE = 'W18_W18F1020_DETAIL_COMPLETE';
export const W18_W18F1020_SCHEDULE_COMPLETE = 'W18_W18F1020_SCHEDULE_COMPLETE';


/*
* W18F1040
* Thong tin bo sung
* */
export const W18_W18F1040_LOAD_COMPLETE = 'W18_W18F1040_LOAD_COMPLETE';
export const W18_W18F1040_ADD_COMPLETE = 'W18_W18F1040_ADD_COMPLETE';
export const W18_W18F1040_EDIT_COMPLETE = 'W18_W18F1040_EDIT_COMPLETE';
export const W18_W18F1040_DELETE_COMPLETE = 'W18_W18F1040_DELETE_COMPLETE';
export const W18_W18F1040_CHECKDELETE_COMPLETE = 'W18_W18F1040_CHECKDELETE_COMPLETE';


/*
* W18F1040-W18F1041
* Chi tiet Thong tin bo sung
* */
export const W18_W18F1041_LOAD_COMPLETE = 'W18_W18F1041_LOAD_COMPLETE';
export const W18_W18F1041_ADD_COMPLETE = 'W18_W18F1041_ADD_COMPLETE';
export const W18_W18F1041_EDIT_COMPLETE = 'W18_W18F1041_EDIT_COMPLETE';
export const W18_W18F1041_DELETE_COMPLETE = 'W18_W18F1041_DELETE_COMPLETE';
export const W18_W18F1041_CHECKDELETE_COMPLETE = 'W18_W18F1041_CHECKDELETE_COMPLETE';

/*
* W17F1001
* Them moi, cap nhat co cau kinh doanh
* VinhLe
* */
export const W17_W17F1001_GROUPSALESPARENT_COMPLETE = 'W17_W17F1001_GROUPSALESPARENT_COMPLETE';
export const W17_W17F1001_GROUPSALESTYPE_COMPLETE = 'W17_W17F1001_GROUPSALESTYPE_COMPLETE';
export const W17_W17F1001_CHECKISROOT = 'W17_W17F1001_CHECKISROOT';


/*
* W18F1015
* Chon khach hang
* VinhLe Created at 7/3/2019
* */
export const W18_W18F1015_DATAGRID_COMPLETE = 'W18_W18F1015_DATAGRID_COMPLETE';


/*
* W18F1016
* Danh sach khach hang chung
* VinhLe Created at 11/3/2019
* */
export const W18_W18F1016_LOADGRID_COMPLETE = 'W18_W18F1016_LOADGRID_COMPLETE';

/*
* W18F1023
* Popup Co cau kinh doanh
* VinhLe Created at 11/3/2019
* */
export const W18_W18F1023_LOADTREE_COMPLETE = 'W18_W18F1023_LOADTREE_COMPLETE';




/*
* W18F1021
* Danh sách sinh nhật
* */
export const W18_W18F1021_LOAD_COMPLETE = 'W18_W18F1021_LOAD_COMPLETE';
export const W18_W18F1021_GroupSales_COMPLETE = 'W18_W18F1021_GroupSales_COMPLETE';
export const W18_W18F1021_SalesPerson_COMPLETE = 'W18_W18F1021_SalesPerson_COMPLETE';
export const W18_W18F1021_TypeTime_COMPLETE = 'W18_W18F1021_TypeTime_COMPLETE';


/*
* W18F1018
* Nhom hang chu luc, goi tu man hinh W18F1012
* VinhLe Created at 19/3/2019
* */
export const W18_W18F1018_DATAMASTER_COMPLETE = 'W18_W18F1018_DATAMASTER_COMPLETE';
export const W18_W18F1018_POPUP_COMPLETE = 'W18_W18F1018_POPUP_COMPLETE';/*

* W18F101
* Mat hang chu luc, goi tu man hinh W18F1012
* VinhLe Created at 20/3/2019
* */
export const W18_W18F1017_DATAMASTER_COMPLETE = 'W18_W18F1017_DATAMASTER_COMPLETE';
export const W18_W18F1017_POPUP_COMPLETE = 'W18_W18F1017_POPUP_COMPLETE';
export const W18_W18F1017_WAREHOUSE_COMPLETE = 'W18_W18F1017_WAREHOUSE_COMPLETE';

/*
* W18F1019
* Chia se khach hang, goi tu man hinh W18F1010
* VinhLe Created at 28/3/2019
* */
export const W18_W18F1019_DATAGRID_COMPLETE = 'W18_W18F1019_DATAGRID_COMPLETE';

/*
* W17F0010
* Thiet lap he thong
* Vinh Updated at 12/4/2019
* */
export const W17_W17F0010_LOAD_COMPLETE = 'W17_W17F0010_LOAD_COMPLETE';
export const W17_W17F0010_LOAD_FIELDEQUIRED_COMPLETE = 'W17_W17F0010_LOAD_FIELDEQUIRED_COMPLETE';
export const W17_W17F0010_NUMDATECHANGE_COMPLETE = 'W17_W17F0010_NUMDATECHANGE_COMPLETE';//Vinh bo sung
export const W17_W17F0010_CONDITION_COMPLETE = 'W17_W17F0010_CONDITION_COMPLETE'; // Vinh bo sung
export const W17_W17F0010_DATACONDITION_COMPLETE = 'W17_W17F0010_DATACONDITION_COMPLETE'; // Vinh bo sung
export const W17_W17F0010_EDIT_COMPLETE = 'W17_W17F0010_EDIT_COMPLETE';/*

/* W19F1000
* Danh sach bao gia
* VINHLE Created at 9/4/2019
*/
export const W19_W19F1000_DATAGRID_COMPLETE = 'W19_W19F1000_DATAGRID_COMPLETE';
export const W19_W19F1000_CBO_INVENTORY_COMPLETE = 'W19_W19F1000_CBO_INVENTORY_COMPLETE';
export const W19_W19F1000_DECIMAL_COMPLETE = 'W19_W19F1000_DECIMAL_COMPLETE';

/* W19F2000
* Danh sach don hang
* RocaChien Created at 24/4/2020
*/
export const W19_W19F2000_DATAGRID_COMPLETE         = 'W19_W19F2000_DATAGRID_COMPLETE';
export const W19_W19F2000_DECIMAL_COMPLETE          = 'W19_W19F2000_DECIMAL_COMPLETE';
export const W19_W19F2000_CBO_INVENTORY_COMPLETE    = 'W19_W19F2000_CBO_INVENTORY_COMPLETE';
export const W19_W19F2001_CBOSHARE_COMPLETE         = 'W19_W19F2001_CBOSHARE_COMPLETE';
export const W19_W19F2001_CBOSHIPADDRESS_COMPLETE   = 'W19_W19F2001_CBOSHIPADDRESS_COMPLETE';
export const W19_W19F2001_CBOSTATUS_COMPLETE        = 'W19_W19F2001_CBOSTATUS_COMPLETE';
export const W19_W19F2001_DATAMASTER_COMPLETE       = 'W19_W19F2001_DATAMASTER_COMPLETE';
export const W19_W19F2001_DATAGRID_COMPLETE         = 'W19_W19F2001_DATAGRID_COMPLETE';
export const W19_W19F2001_PERAPPROVE_COMPLETE       = 'W19_W19F2001_PERAPPROVE_COMPLETE';
export const W19_W19F2001_NUMVALUE_COMPLETE         = 'W19_W19F2001_NUMVALUE_COMPLETE';
export const W19_W19F2001_EXTRAINFO_COMPLETE        = 'W19_W19F2001_EXTRAINFO_COMPLETE';

/* W19F1001
* Lap bao gia
* VINHLE Created at 9/4/2019
* */
export const W19_W19F1001_CBOSHARE_COMPLETE = 'W19_W19F1001_CBOSHARE_COMPLETE';
export const W19_W19F1001_CBOSHIPADDRESS_COMPLETE = 'W19_W19F1001_CBOSHIPADDRESS_COMPLETE';
export const W19_W19F1001_CBOSTATUS_COMPLETE = 'W19_W19F1001_CBOSTATUS_COMPLETE';
export const W19_W19F1001_DATAMASTER_COMPLETE = 'W19_W19F1001_DATAMASTER_COMPLETE';
export const W19_W19F1001_DATAGRID_COMPLETE = 'W19_W19F1001_DATAGRID_COMPLETE';
export const W19_W19F1001_PERAPPROVE_COMPLETE = 'W19_W19F1001_PERAPPROVE_COMPLETE';
export const W19_W19F1001_NUMVALUE_COMPLETE = 'W19_W19F1001_NUMVALUE_COMPLETE';
export const W19_W19F1001_EXTRAINFO_COMPLETE = 'W19_W19F1001_EXTRAINFO_COMPLETE';





/*
* W18F1022
* Lich su va chuyen chu so huu
* */
export const W18_W18F1022_LOAD_COMPLETE                         = 'W18_W18F1022_LOAD_COMPLETE';
export const W18_W18F1022_LOAD_TRANSTYPE_COMPLETE               = 'W18_W18F1022_LOAD_TRANSTYPE_COMPLETE';

/*
* W19F1010
* Chi tieu doanh so
* */
export const W19_W19F1010_LOAD_COMPLETE                         = 'W19_W19F1010_LOAD_COMPLETE';
export const W19_W19F1010_CBGROUPSALE_COMPLETE                  = 'W19_W19F1010_CBGROUPSALE_COMPLETE';
export const W19_W19F1010_CBYEAR_COMPLETE                       = 'W19_W19F1010_CBYEAR_COMPLETE';
export const W19_W19F1010_SAVE_COMPLETE                         = 'W19_W19F1010_SAVE_COMPLETE';

/*
* W19F1020
* Danh sach don hang
* */
export const W19_W19F1020_DATAGRID_COMPLETE                     = 'W19_W19F1020_DATAGRID_COMPLETE';
export const W19_W19F1020_CB_TYPE_TIME_COMPLETE                 = 'W19_W19F1020_CB_TYPE_TIME_COMPLETE';
export const W19_W19F1020_LOAD_DATA_COMPLETE                    = 'W19_W19F1020_LOAD_DATA_COMPLETE';
export const W19_W19F1021_MASTER_COMPLETE                       = 'W19_W19F1021_MASTER_COMPLETE';
export const W19_W19F1021_DATAGRID_COMPLETE                     = 'W19_W19F1021_DATAGRID_COMPLETE';
export const W19_W19F1021_LOAD_BASE_CURRENCY_COMPLETE           = 'W19_W19F1021_LOAD_BASE_CURRENCY_COMPLETE';
export const W19_W19F1021_LOAD_LIST_STATUS_COMPLETE             = 'W19_W19F1021_LOAD_LIST_STATUS_COMPLETE';



/*
* W19F1020
* Lich su gui mail
* */
export const W17_W17F0005_DATAGRID_COMPLETE                     = 'W17_W17F0005_DATAGRID_COMPLETE';

/* Dashboard */
export const GET_LIST_PART_COMPLETE = 'GET_LIST_PART_COMPLETE';
export const GET_LIST_REPORT_COMPLETE = 'GET_LIST_REPORT_COMPLETE';
export const GET_GRID_REPORT_COMPLETE = 'GET_GRID_REPORT_COMPLETE';
export const GET_TYPE_TIME_COMPLETE = 'GET_TYPE_TIME_COMPLETE';
export const GET_TYPE_TIME2_COMPLETE = 'GET_TYPE_TIME2_COMPLETE';

/* Device */
export const ADD_DEVICE_COMPLETE = 'ADD_DEVICE_COMPLETE';

/* W18F4030
* Bao cao hoat dong theo khach hang
* VINHLE Created at 5/6/2019
* */
export const W18F4030_LOAD_REPORT_COMPLETE      = 'W18F4030_LOAD_REPORT_COMPLETE';
export const W18F4030_LOAD_TASK_TYPE_COMPLETE   = 'W18F4030_LOAD_TASK_TYPE_COMPLETE';
export const W18F4030_LOAD_STATUS_COMPLETE      = 'W18F4030_LOAD_STATUS_COMPLETE';
/* Main */
export const GET_NUMBER_BADGE_COMPLETE          = 'GET_NUMBER_BADGE_COMPLETE';
export const UPDATE_NUMBER_BADGE_COMPLETE       = 'UPDATE_NUMBER_BADGE_COMPLETE';
export const LIST_NOTIFICATIONS_COMPLETE        = 'LIST_NOTIFICATIONS_COMPLETE';
export const UPDATE_NOTIFICATIONS_COMPLETE      = 'UPDATE_NOTIFICATIONS_COMPLETE';


/* W17F0043
* THIẾT LẬP HIỂN THỊ BÁO CÁO TRÊN DASHBOARD
* */
export const W17F0043_LOAD_REPORT_LIST_COMPLETE      = 'W17F0043_LOAD_REPORT_LIST_COMPLETE';
export const W17F0043_UPDATE_COMPLETE      = 'W17F0043_UPDATE_COMPLETE';

/* W18F1025
* BAN GIAO
* */
export const W18_W18F1025_HANDOVER_COMPLETE      = 'W18_W18F1025_HANDOVER_COMPLETE';/* W18F1025


* W17F0050
*Danh sach Template Mail
*VINH LE Created at 23/7/2019
* */
export const W17_W17F0050_DATAGRID_COMPLETE      = 'W17_W17F0050_DATAGRID_COMPLETE';

/* W18F1026
* Chuyển đổi trạng thái
* */
export const W18_W18F1026_LOAD_COMBO_COMPLETE      = 'W18_W18F1026_LOAD_COMBO_COMPLETE';
export const W18_W18F1026_SAVE_COMPLETE            = 'W18_W18F1026_SAVE_COMPLETE';

/* W18F1050
* Danh Sach dia ban
* Vinh Le Created at 16/7/2019
* */
export const W18_W18F1050_DATAGRID_COMPLETE                 = 'W18_W18F1050_DATAGRID_COMPLETE';
export const W18_W18F1050_CB_INFO_COMPLETE                  = 'W18_W18F1050_CB_INFO_COMPLETE';
export const W18_W18F1050_CB_INFO_TYPE_COMPLETE             = 'W18_W18F1050_CB_INFO_TYPE_COMPLETE';
export const W18_W18F1050_CB_INFO_VALUE_COMPLETE            = 'W18_W18F1050_CB_INFO_VALUE_COMPLETE';
export const W18_W18F1050_CB_INFO_REF_COMPLETE              = 'W18_W18F1050_CB_INFO_REF_COMPLETE';
export const W18_W18F1050_CB_VALUE_REF_COMPLETE             = 'W18_W18F1050_CB_VALUE_REF_COMPLETE';
export const W18_W18F1050_DELETE_COMPLETE                   = 'W18_W18F1050_DELETE_COMPLETE';
export const W18_W18F1051_LOAD_MASTER_COMPLETE              = 'W18_W18F1051_LOAD_MASTER_COMPLETE';
export const W18_W18F1051_LOAD_PROVINCE_COMPLETE            = 'W18_W18F1051_LOAD_PROVINCE_COMPLETE';
export const W18_W18F1051_LOAD_TYPE_INFORMATION_COMPLETE    = 'W18_W18F1051_LOAD_TYPE_INFORMATION_COMPLETE';
export const W18_W18F1051_LOAD_CODE_INFORMATION_COMPLETE    = 'W18_W18F1051_LOAD_CODE_INFORMATION_COMPLETE';
export const W18_W18F1051_LOAD_TYPE_CONDITION_COMPLETE      = 'W18_W18F1051_LOAD_TYPE_CONDITION_COMPLETE';
export const W18_W18F1051_LOAD_CODE_CONDITION_COMPLETE      = 'W18_W18F1051_LOAD_CODE_CONDITION_COMPLETE';
export const W18_W18F1051_ADD_COMPLETE                      = 'W18_W18F1051_ADD_COMPLETE';
export const W18_W18F1051_EDIT_COMPLETE                     = 'W18_W18F1051_EDIT_COMPLETE';


/* W18F1061
* Cap nhat tinh huong
* TRIHAO - 02/10/2019
* */
export const W18_W18F1061_CB_CUSTOMERS_COMPLETE                 = 'W18_W18F1061_CB_CUSTOMERS_COMPLETE';
export const W18_W18F1061_CB_CASETYPE_COMPLETE                 = 'W18_W18F1061_CB_CASETYPE_COMPLETE';
export const W18_W18F1061_CB_MEDIA_COMPLETE                 = 'W18_W18F1061_CB_MEDIA_COMPLETE';
export const W18_W18F1061_CB_STATUS_COMPLETE                 = 'W18_W18F1061_CB_STATUS_COMPLETE';
export const W18_W18F1061_CB_CREATEPERSON_COMPLETE                 = 'W18_W18F1061_CB_CREATEPERSON_COMPLETE';
export const W18_W18F1061_CB_SALEPERSON_COMPLETE                 = 'W18_W18F1061_CB_SALEPERSON_COMPLETE';
export const W18_W18F1061_EDIT_FORM_COMPLETE                 = 'W18_W18F1061_EDIT_FORM_COMPLETE';
export const W18_W18F1061_ADD_COMPLETE                       = 'W18_W18F1061_ADD_COMPLETE';
export const W18_W18F1061_EDIT_COMPLETE                      = 'W18_W18F1061_EDIT_COMPLETE';

/* W19F1030
* Danh sách đánh giá
* */
export const W19_W19F1030_DATAGRID_COMPLETE                 = 'W19_W19F1030_DATAGRID_COMPLETE';
export const W19_W19F1030_CB_SALEPERSON_COMPLETE            = 'W19_W19F1030_CB_SALEPERSON_COMPLETE';
export const W19_W19F1030_CB_EVALUATION_COMPLETE            = 'W19_W19F1030_CB_EVALUATION_COMPLETE';
export const W19_W19F1030_CB_TYPETIME_COMPLETE              = 'W19_W19F1030_CB_TYPETIME_COMPLETE';
export const W19_W19F1030_DELETE_COMPLETE                   = 'W19_W19F1030_DELETE_COMPLETE';


/* W19F1031
* Cap nhat danh gia
* Vinh Le Created at 13/8/2019
* */
export const W19_W19F1031_CBOEVALUATE_COMPLETE  = 'W19_W19F1031_CBOEVALUATE_COMPLETE';


/* W17F1510
* Danh mục đánh giá chung
* */
export const W17_W17F1510_DATAGRID_COMPLETE                 = 'W17_W17F1510_DATAGRID_COMPLETE';
export const W17_W17F1511_ADD_COMPLETE                      = 'W17_W17F1511_ADD_COMPLETE';
export const W17_W17F1511_EDIT_COMPLETE                     = 'W17_W17F1511_EDIT_COMPLETE';
export const W17_W17F1511_DELETE_COMPLETE                   = 'W17_W17F1511_DELETE_COMPLETE';


/* W20F1000
* Danh sach nhan vien
* */
export const W20_W20F1000_CB_STATUS_COMPLETE                = 'W20_W20F1000_CB_STATUS_COMPLETE';
export const W20_W20F1000_LOAD_MASTER_COMPLETE              = 'W20_W20F1000_LOAD_MASTER_COMPLETE';
export const W20_W20F1000_LOAD_TOTAL_COMPLETE               = 'W20_W20F1000_LOAD_TOTAL_COMPLETE';
export const W20_W20F1000_LOAD_DETAIL_COMPLETE              = 'W20_W20F1000_LOAD_DETAIL_COMPLETE';
export const W20_W20F1000_CBO_GENDER_COMPLETE               = 'W20_W20F1000_CBO_GENDER_COMPLETE';

/* W18F1060
* Danh sach tinh huong
* */
export const W18_W18F1060_GET_COMBO_STATUS                  = "W18_W18F1060_GET_COMBO_STATUS";
export const W18_W18F1060_GET_COMBO_FEEDBACK                = "W18_W18F1060_GET_COMBO_FEEDBACK";
export const W18_W18F1060_GET_CASE_TYPE                     = "W18_W18F1060_GET_CASE_TYPE";
export const W18_W18F1060_DELETE                            = "W18_W18F1060_GET_CASE_DELETE";/* W18F1060

* Bao cao don hang theo ma hang
* */
export const W18_W18F4040_GETDECIMAL_COMPLETE               = "W18_W18F4040_GETDECIMAL_COMPLETE";


/* W18F1060
* Tim kiem nang cao
* */
export const LIST_DEFAULT_FILTER_ADVANCED_SEARCH             = "LIST_DEFAULT_FILTER_ADVANCED_SEARCH";
export const POPOVER_FILTER_DETAIL_ADVANCED_SEARCH           = "POPOVER_FILTER_DETAIL_ADVANCED_SEARCH";
export const CHECK_MANAGER_ADVANCED_SEARCH                   = "CHECK_MANAGER_ADVANCED_SEARCH";

/*w18F4040
*Thông tin công nợ
* */
export const W18_W18F4050_DATADEBTMETHOD_COMPLETE             = "W18_W18F4050_DATADEBTMETHOD_COMPLETE";

/*W94F100
*Danh sách báo cáo
* */
export const W94_W94F1000_GET_GROUP_LIST_COMPLETE             = "W94_W94F1000_GET_GROUP_LIST_COMPLETE";

/* W19F1001
* Man hinh bao gia moi
* ANHTAI Created at 19/01/2021
* */
export const W17_W17F2000_GET_CBO_DIVISION_SUCCESS = "W17_W17F2000_GET_CBO_DIVISION_SUCCESS";
export const W17_W17F2000_GET_CBO_TRANTYPE_SUCCESS = "W17_W17F2000_GET_CBO_TRANTYPE_SUCCESS";
export const W17_W17F2000_GET_CBO_EMPLOYEE_SUCCESS = "W17_W17F2000_GET_CBO_EMPLOYEE_SUCCESS";
export const W17_W17F2000_GET_CBO_DELIVERY_SUCCESS = "W17_W17F2000_GET_CBO_DELIVERY_SUCCESS";
export const W17_W17F2000_GET_CBO_PAYMENT_TERM_SUCCESS = "W17_W17F2000_GET_CBO_PAYMENT_TERM_SUCCESS";
export const W17_W17F2000_GET_CBO_PAYMENT_METHOD_SUCCESS = "W17_W17F2000_GET_CBO_PAYMENT_METHOD_SUCCESS";
export const W17_W17F2000_GET_CBO_CURRENCY_SUCCESS = "W17_W17F2000_GET_CBO_CURRENCY_SUCCESS";
export const W17_W17F2000_GET_CBO_STATUS_SUCCESS = "W17_W17F2000_GET_CBO_STATUS_SUCCESS";
export const W17_W17F2000_GET_CBO_VAT_GROUP_SUCCESS = "W17_W17F2000_GET_CBO_VAT_GROUP_SUCCESS";
export const W17_W17F2000_GET_CBO_CONTACT_PERSON_SUCCESS = "W17_W17F2000_GET_CBO_CONTACT_PERSON_SUCCESS";
export const W17_W17F2000_GET_DMF_SETTING_SUCCESS = "W17_W17F2000_GET_DMF_SETTING_SUCCESS";
export const W17_W17F2000_GET_SHIP_ADDRESS_SUCCESS = "W17_W17F2000_GET_SHIP_ADDRESS_SUCCESS";
export const W17_W17F2000_GET_CBO_PACKING_SUCCESS = "W17_W17F2000_GET_CBO_PACKING_SUCCESS";
export const W17_W17F2000_GET_VIRTUAL_ITEMS_VALUE_SUCCESS = "W17_W17F2000_GET_VIRTUAL_ITEMS_VALUE_SUCCESS";
export const W17_W17F2000_GET_CBO_UNIT_SUCCESS = "W17_W17F2000_GET_CBO_UNIT_SUCCESS";

/* W17F2040
* Man hinh bao gia moi
* ANHTAI Created at 04/03/2021
* */
export const W17_W17F2040_GET_CBO_DIVISION_SUCCESS = "W17_W17F2040_GET_CBO_DIVISION_SUCCESS";
export const W17_W17F2040_GET_CBO_TRANTYPE_SUCCESS = "W17_W17F2040_GET_CBO_TRANTYPE_SUCCESS";
export const W17_W17F2040_GET_CBO_EMPLOYEE_SUCCESS = "W17_W17F2040_GET_CBO_EMPLOYEE_SUCCESS";
export const W17_W17F2040_GET_CBO_DELIVERY_SUCCESS = "W17_W17F2040_GET_CBO_DELIVERY_SUCCESS";
export const W17_W17F2040_GET_CBO_PAYMENT_TERM_SUCCESS = "W17_W17F2040_GET_CBO_PAYMENT_TERM_SUCCESS";
export const W17_W17F2040_GET_CBO_PAYMENT_METHOD_SUCCESS = "W17_W17F2040_GET_CBO_PAYMENT_METHOD_SUCCESS";
export const W17_W17F2040_GET_CBO_CURRENCY_SUCCESS = "W17_W17F2040_GET_CBO_CURRENCY_SUCCESS";
export const W17_W17F2040_GET_CBO_STATUS_SUCCESS = "W17_W17F2040_GET_CBO_STATUS_SUCCESS";
export const W17_W17F2040_GET_CBO_VAT_GROUP_SUCCESS = "W17_W17F2040_GET_CBO_VAT_GROUP_SUCCESS";
export const W17_W17F2040_GET_CBO_CONTACT_PERSON_SUCCESS = "W17_W17F2040_GET_CBO_CONTACT_PERSON_SUCCESS";
export const W17_W17F2040_GET_DMF_SETTING_SUCCESS = "W17_W17F2040_GET_DMF_SETTING_SUCCESS";
export const W17_W17F2040_GET_CBO_VAT_SUCCESS = "W17_W17F2040_GET_CBO_VAT_SUCCESS";
export const W17_W17F2040_GET_CBO_WAREHOUSE_SUCCESS = "W17_W17F2040_GET_CBO_WAREHOUSE_SUCCESS";

/* W17F1005
* Man hinh tien do bao gia
* ANHTAI Created at 22/04/2023
* */
export const W17_W17F1005_ORIGINAL_DECIMAL_COMPLETE = "W17_W17F1005_ORIGINAL_DECIMAL_COMPLETE";
export const W17_W17F1005_GET_DATA_SETTING_FORM = "W17_W17F1005_GET_DATA_SETTING_FORM";

































